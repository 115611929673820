
import Util from '@/function/util'

import { runASWEvent as saveR, remove } from '@/function/option'
import { runtimeData } from '@/function/msg'
import { Connector, login } from '@/function/connect'

export default {
    name: 'ViewOptAccount',
    props: [],
    data() {
        return {
            runtimeData: runtimeData,
            save: saveR,
            login: login
        }
    },
    methods: {
        /**
         * 对 botInfo 字段部分需要处理的数据进行处理
         * @param name 键名
         * @param value 键值
         */
        paseBotInfo(name: string, value: number | string) {
            if (typeof value == 'number' && name.indexOf('time') > 0 && value > 1000000000) {
                // 尝试转换时间戳
                if (value / 10000000000 < 1) {
                    value = value * 1000
                }
                return Intl.DateTimeFormat(
                    Util.getTrueLang(),
                    { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(new Date(value))
            }
            return value
        },

        /**
         * 断开连接
         */
        exitConnect() {
            remove('auto_connect')
            Connector.close()
        },

        /**
         * 设置昵称
         * @param event 事件
         */
        setNick(event: KeyboardEvent) {
            // TODO: 这玩意的返回好像永远是错误的 …… 所以干脆不处理返回了
            if (event.key === 'Enter' && runtimeData.loginInfo.nickname !== '') {
                Connector.send('set_nickname', {nickname: runtimeData.loginInfo.nickname}, 'setNickname')
            }
        },
        
        /**
         * 设置签名
         * @param event 事件
         */
        setLNick(event: KeyboardEvent) {
            // TODO: 这玩意的返回好像永远是错误的 …… 所以干脆不处理返回了
            if (event.key === 'Enter' && runtimeData.loginInfo.info.lnick !== '') {
                Connector.send('set_signature', {signature: runtimeData.loginInfo.info.lnick}, 'setSignature')
            }
        }
    }
}
