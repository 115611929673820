import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, setBlockTracking as _setBlockTracking, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-372b1fd0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["data-url", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.item.type == 'xml')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          innerHTML: _ctx.View.buildXML(_ctx.item.data, _ctx.item.id, _ctx.id),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.View.cardClick('xml-' + _ctx.id)))
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.info.type == 'default')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                innerHTML: _ctx.buildJSON(_ctx.info, _ctx.id),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.View.cardClick('json-' + _ctx.id)))
              }, null, 8, _hoisted_3))
            : (_ctx.info.type == 'tencent.map')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "msg-comp-map",
                  onClick: ($event: any) => (_ctx.View.cardClick('map-' + _ctx.id))
                }, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.info.app.title), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.info.app.desc), 1),
                  _createElementVNode("div", {
                    class: "map",
                    "data-url": _ctx.createMap(),
                    "data-urlOpenType": "_self",
                    id: 'map-' + _ctx.id
                  }, null, 8, _hoisted_5)
                ], 8, _hoisted_4))
              : _createCommentVNode("", true)
        ]))
  ]))
}