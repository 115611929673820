
import Xss from 'xss'
import { defineComponent } from 'vue'
import { runtimeData } from '@/function/msg'

export default defineComponent({
    name: 'BulletinBody',
    props: ['data', 'index'],
    data() {
        return {
            Xss: Xss,
            runtimeData: runtimeData,
            showAll: false,
            needShow: true
        }
    },
    mounted() {
        this.$nextTick(() => {
            const tab1 = document.getElementById('info-pan-notices')
            const tab2 = document.getElementById('info-pan-mumber')
            const pan = document.getElementById('bulletins-msg-' + this.index)
            if(pan && tab1 && tab2) {
                // PS：display none 不渲染无法获取实际高度
                tab1.click()
                let maxHeight = Number(getComputedStyle(pan).maxHeight.replace('px', ''))
                const height = pan.offsetHeight
                tab2.click()
                this.needShow = height == maxHeight
            }
        })
    }
})
