
import { defineComponent, ref } from 'vue'
import { runtimeData } from '@/function/msg'
import { getTrueLang } from '@/function/util'

export default defineComponent({
    name: 'NoticeBody',
    props: ['data'],
    data() {
        return {
            trueLang: getTrueLang(),
            info: ref(this.data) as { [key: string]: any }
        }
    },
    methods: {
        getTimeConfig(date: Date) {
            let base = { hour: "numeric", minute: "numeric", second: "numeric" } as Intl.DateTimeFormatOptions
            const nowDate = new Date()
            const todayDate = new Date().setHours(0, 0, 0, 0)
            const paramsDate = date.setHours(0, 0, 0, 0)
            if(todayDate != paramsDate) {
                if (nowDate.getFullYear() == date.getFullYear() && nowDate.getMonth() == date.getMonth()) {
                    base.weekday = 'short'
                } else if(nowDate.getFullYear() == date.getFullYear()) {
                    base.day = 'numeric'
                    base.month = 'short'
                } else {
                    base.day = 'numeric'
                    base.month = 'short'
                    base.year = 'numeric'
                }
            }
            return base
        }
    },
    mounted() {
        if (this.info.sub_type === 'recall') {
            // 补全撤回者信息
            if (runtimeData.chatInfo.show.type === 'group') {
                const id = this.info.operator_id
                // 寻找群成员信息
                if (runtimeData.chatInfo.info.group_members !== undefined) {
                    const back = runtimeData.chatInfo.info.group_members.filter((item) => {
                        return item.user_id === Number(id)
                    })
                    if (back.length === 1) {
                        this.info.name = back[0].card === '' ? back[0].nickname : back[0].card
                    } else {
                        this.info.name = id
                    }
                } else {
                    this.info.name = id
                }
            } else {
                this.info.name = runtimeData.chatInfo.show.name
            }
        }
    }
})
