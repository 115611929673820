
import { defineComponent } from 'vue'
import { runtimeData } from '@/function/msg'
import { Connector } from '@/function/connect'

export default defineComponent({
    name: 'ViewOptInfo',
    props: ['type', 'chat'],
    data() {
        return {
            runtimeData: runtimeData
        }
    },
    methods: {
        /**
         * 设置群名片
         * @param event 按键事件
         */
        setGroupCard(event: KeyboardEvent) {
            if (event.key === 'Enter') {
                Connector.send(
                    'set_group_card', 
                    {
                        group_id: this.chat.show.id,
                        user_id: runtimeData.loginInfo.uin,
                        card: runtimeData.chatInfo.info.me_info.card
                    },
                    'setGroupCard'
                )
            }
        },

        /**
         * 设置群名
         * @param event 按键事件
         */
        setGroupName(event: KeyboardEvent) {
            if (event.key === 'Enter' && runtimeData.chatInfo.show.name != '') {
                Connector.send(
                    'set_group_name', 
                    {
                        group_id: this.chat.show.id,
                        group_name: runtimeData.chatInfo.show.name
                    },
                    'setGroupName'
                )
            }
        }
    }
})
