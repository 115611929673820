
import { defineComponent } from 'vue'
import { Connector } from '@/function/connect'
import { runtimeData } from '@/function/msg'

import Option from '@/function/option'
import { MsgItemElem, SQCodeElem } from '@/function/elements/information'

export default defineComponent({
  name: 'FacePan',
  props: ['display'],
  data () {
    return {
      Option: Option,
      runtimeData: runtimeData,
      baseFaceMax: 323,
      baseFacePass: [17, 40, 44, 45, 47, 48, 50, 51, 52, 58, 65, 68, 70, 71, 73, 80, 81, 82, 83, 84, 87, 88, 92, 93, 94, 95, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 275, 276]
    }
  },
  methods: {
    addSpecialMsg(json: MsgItemElem, addText: boolean) {
      this.$emit('addSpecialMsg', { addText: addText, msgObj: json } as SQCodeElem )
    },
    addBaseFace(id: number) {
      this.addSpecialMsg({type: 'face', id: id}, true)
    },
    addImgFace(url: string) {
      this.addSpecialMsg({type: 'image', file: url, cache: true, asface: true}, true)
    }
  },
  mounted () {
    // 加载漫游表情
    if (runtimeData.stickerCache === undefined) {
      Connector.send('get_roaming_stamp', {}, 'getRoamingStamp')
    }
  }
})
