
import { defineComponent } from 'vue'

import { getTrueLang, getSizeFromBytes, htmlDecodeByRegExp } from '@/function/util'
import { Connector } from '@/function/connect'
import { runtimeData } from '@/function/msg'

export default defineComponent({
    name: 'FileBody',
    props: ['item', 'chat', 'parent'],
    data () {
        return {
            trueLang: getTrueLang(),
            getSize: getSizeFromBytes,
            toHtml: htmlDecodeByRegExp
        }
    },
    methods: {
        /**
         * 下载文件（获取文件下载地址并下载）
         */
        getFile (item: { [key: string]: any }) {
            if (this.parent === undefined) {
                Connector.send('get_file_url', {
                    id: runtimeData.chatInfo.show.id,
                    message_id: runtimeData.messageList[0].message_id,
                    fid: item.id
                }, 'downloadGroupFile_' + item.id)
            } else {
                // 对于文件夹里的文件需要再找一次 ……
                Connector.send('http_proxy', {
                    id: runtimeData.chatInfo.show.id,
                    message_id: runtimeData.messageList[0].message_id,
                    fid: item.id
                }, 'downloadGroupFile_' + this.parent + '_' + item.id)
            }
            // PS：在发起下载后就要将百分比设置好 …… 因为下载部分不一定立刻会开始
            // 这时候如果用户疑惑为什么点了没反应会多次操作的（用户竟是我自己）
            item.downloadingPercentage = 0
        },
        /**
         * 加载子文件夹
         */
        loadFileDir (id: string, type: number) {
            if (type === 2 && this.item.sub_list === undefined) {
                // 加载群文件列表
                const url = `https://pan.qun.qq.com/cgi-bin/group_file/get_file_list?gc=${this.chat.show.id}&bkn=${runtimeData.loginInfo.bkn}&start_index=0&cnt=30&filter_code=0&folder_id=${id}&show_onlinedoc_folder=0`
                Connector.send('http_proxy', { 'url': url }, 'getGroupDirFiles_' + id)
            }
        }
    }
})
