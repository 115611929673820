
import languages from '@/assets/l10n/_l10nconfig.json'

import { defineComponent } from 'vue'
import { runtimeData } from '@/function/msg'
import { runASWEvent as save } from '@/function/option'

export default defineComponent({
    name: 'WelcomePan',
    props: ['data'],
    data() {
        return {
            languages: languages,
            runtimeData: runtimeData,
            save: save,
            show: 'home'
        }
    },
    methods: {
        gaLanguage(event: Event) {
            const sender = event.target as HTMLInputElement
            // GA：上传语言选择
            this.$gtag.event('use_language', { name: sender.value })
        },
        setPage(name: string) {
            this.show = name
        }
    }
})
