
import { defineComponent } from 'vue'
import { MsgBodyFuns as ViewFuns } from '@/function/model/msg-body'

export default defineComponent({
    name: 'CardMessage',
    props: [ 'item', 'id' ],
    components: {},
    data() {
        return {
            View: ViewFuns,
            info: ViewFuns.getJSONType(this.item.data),
        }
    },
    methods: {
        /**
         * 构建基础 JSON 消息
         * @param info 卡片信息
         * @param id 消息 ID
         */
        buildJSON(data: any, id: string) {
            const info = data.app
            const div = document.createElement('div')
            // 构建 HTML
            const html = '<p>' + info.title + '</p>' +
                '<span>' + info.desc + '</span>' +
                '<img style="' + (info.preview === undefined ? 'display:none' : '') + '" src="' + info.preview + '">' +
                (info.name ? '<div><img src="' + info.icon + '"><span>' + info.name + '</span></div>' : '')

            div.className = 'msg-json'
            div.id = 'json-' + id
            div.dataset.url = info.url
            div.dataset.urlOpenType = info.urlOpenType
            div.innerHTML = html
            // 附加信息
            if(Object.keys(data.append).length > 0) {
                // 将 append 里的信息附加到 div 上
                for(const key in data.append) {
                    div.dataset[key] = data.append[key]
                }
            }
            // 返回
            return div.outerHTML
        },

        /**
         * 创建高德地图模块
         */
         createMap() {
            const json = JSON.parse(this.item.data)
            window.createMap(
                process.env.VUE_APP_AMAP_KEY,
                this.id,
                {
                    lat: json.meta['Location.Search'].lat,
                    lng: json.meta['Location.Search'].lng
                }
            )
            return this.info.app.url
        }
    }
})
