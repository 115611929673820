
import { defineComponent } from 'vue'
import { runASWEvent as save } from '@/function/option'
import { runtimeData } from '@/function/msg'

export default defineComponent({
    name: 'ViewOptFunction',
    data() {
        return {
            runtimeData: runtimeData,
            save: save,
            ndt: 0,
            ndv: false
        }
    },
    methods: {
        msgND: function () {
            this.ndt++
            setTimeout(() => {
                this.ndv = false
            }, 300)
        },
        breakLineTip (event: Event) {
            const sender = event.target as HTMLInputElement
            if(sender.checked) {
                const popInfo = {
                    title: this.$t('popbox_tip'),
                    html: `<span>${this.$t('option_dev_chat_use_breakline_tip_1')}</span>`,
                    button: [
                        {
                            text: this.$t('btn_know'),
                            master: true,
                            fun: () => { runtimeData.popBoxList.shift() }
                        }
                    ]
                }
                runtimeData.popBoxList.push(popInfo)
            }
        }
    }
})
