
import { defineComponent } from 'vue'

import packageInfo from '../../package.json'
import Util from '@/function/util'

import OptAccount from './options/OptAccount.vue'
import OptView from './options/OptView.vue'
import OptDev from './options/OptDev.vue'
import OptFunction from './options/OptFunction.vue'

import { ContributorElem } from '@/function/elements/system'

export default defineComponent({
    name: 'ViewOption',
    props: {
        config: {} as { [key: string]: string | number | boolean }
    },
    components: { OptAccount, OptView, OptDev, OptFunction },
    data() {
        return {
            constList: [] as ContributorElem[],
            packageInfo: packageInfo,
            util: Util
        }
    },
    mounted() {
        // 加载贡献者信息
        fetch('https://api.github.com/repos/stapxs/stapxs-qq-lite-2.0/contributors')
            .then(response => response.json())
            .then((data: { [key: string]: string }[]) => {
                for (let i = 0; i < data.length; i++) {
                    this.constList.push({
                        url: data[i].avatar_url,
                        link: data[i].html_url,
                        title: data[i].login,
                        isMe: data[i].login == 'Stapxs'
                    })
                }
            })
            .catch(console.error)
    }
})
