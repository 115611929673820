
import app from '@/main'
import BulletinBody from '@/components/BulletinBody.vue'
import FileBody from '@/components/FileBody.vue'
import OptInfo from './options/OptInfo.vue'

import { defineComponent, toRaw } from 'vue'
import { getTrueLang } from '@/function/util'
import { runtimeData } from '@/function/msg'
import { UserFriendElem, UserGroupElem } from '@/function/elements/information'

export default defineComponent({
    name: 'ViewInfo',
    props: ['tags', 'chat'],
    components: { BulletinBody, FileBody, OptInfo },
    data() {
        return {
            runtimeData: runtimeData,
            trueLang: getTrueLang(),
            isTop: false,
            number_cache: [] as any[]
        }
    },
    methods: {
        /**
         * 关闭面板
         */
        closeChatInfoPan() {
            this.$emit('close', null)
        },

        /**
         * 加载更多文件
         * @param event 滚动事件
         */
        fileLoad(event: Event) {
            this.$emit('loadFile', event)
        },

        /**
         * 发起聊天
         */
        startChat(info: any) {
            // 如果是自己的话就忽略
            if (info.user_id != runtimeData.loginInfo.uin) {
                // 检查这个人是否已经在聊天列表中
                let chat = runtimeData.onMsgList.find((item: UserFriendElem & UserGroupElem) => {
                    return item.user_id == info.user_id
                })
                if(!chat) {
                    // 检查这个人是不是好友
                    let friend = runtimeData.userList.find((item: UserFriendElem & UserGroupElem) => {
                        return item.user_id == info.user_id
                    })
                    if(friend) {
                        runtimeData.onMsgList.push(friend)
                        chat = friend
                    } else {
                        // 创建一个临时聊天
                        const user = {
                            user_id: info.user_id,
                            // 因为临时消息没有返回昵称
                            nickname: app.config.globalProperties.$t('chat_temp'),
                            remark: info.user_id,
                            group_id: info.group_id,
                            group_name: ''
                        } as UserFriendElem & UserGroupElem
                        runtimeData.onMsgList.push(user)
                        chat = user
                    }
                }
                // 切换到这个聊天
                this.$nextTick(() => {
                    if(chat) {
                        const item = document.getElementById('user-' + chat.user_id)
                        if(item) {
                            item.click()
                        }
                    }
                })
            }
        },

        searchList(event: Event) {
            const value = (event.target as HTMLInputElement).value
            if (value !== '') {
                this.number_cache = toRaw(this.chat.info.group_members)
                this.number_cache = this.number_cache.filter((item) => {
                    const name = item.card.toLowerCase() + '('+ item.nickname.toLowerCase() + ')'
                    const id = item.user_id
                    return name.indexOf(value.toLowerCase()) != -1 || id.toString() === value
                })
            } else {
                this.number_cache = [] as any[]
            }
        }
    }
})
