
import { defineComponent, toRaw } from 'vue'
import { runtimeData } from '../../function/msg'
import { runASWEvent as save, get } from '../../function/option'
import { BrowserInfo, detect } from 'detect-browser'

import languages from '../../assets/l10n/_l10nconfig.json'

export default defineComponent({
    name: 'ViewOptTheme',
    data() {
        return {
            get: get,
            runtimeData: runtimeData,
            save: save,
            languages: languages,
            // 别问我为什么微软是紫色的
            colors: ['林槐蓝', '墨竹青', '少女粉', '微软紫', '坏猫黄', '玄素黑'],
            browser: detect() as BrowserInfo,
            initialScaleShow: 0.1
        }
    },
    methods: {
        gaLanguage(event: Event) {
            const sender = event.target as HTMLInputElement
            // GA：上传语言选择
            this.$gtag.event('use_language', { name: sender.value })
        },

        setInitialScaleShow(event: Event) {
            const sender = event.target as HTMLInputElement
            this.initialScaleShow = Number(sender.value)
        },

        restartapp() {
            const electron = (process.env.IS_ELECTRON as any) === true ? window.require('electron') : null
            const reader = electron ? electron.ipcRenderer : null
            if (reader) {
                reader.send('win:relaunch')
            }
        }
    },
    mounted() {
        // 一次性初始化一次缩放级别
        const watch = this.$watch(
            () => runtimeData.sysConfig,
            () => {
                this.initialScaleShow = toRaw(runtimeData.sysConfig.initial_scale)
                watch()
            }
        )
    }
})
